import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { BoabaasUser } from '../auth/authentication.service';
import { AvgCheckResponse } from '@v2/data/schema/generated/AvgCheckResponse';
import { PrivacyStatementHttpService } from '@v2/data/service/privacy-statement.http.service';

export const avgGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const msalService = inject(MsalService);
  const privacyStatementService = inject(PrivacyStatementHttpService);

  const activeAccount = msalService.instance.getActiveAccount();
  if (!activeAccount) {
    return false;
  }

  const boabaasUser = new BoabaasUser(activeAccount);
  if (boabaasUser.isAdmin) {
    return true;
  }

  return privacyStatementService.check().pipe(
    switchMap((result: AvgCheckResponse) => {
      if (result && !result.hasAgreed) {
        return of(router.createUrlTree(['/privacy-statement']));
      }
      return of(true);
    }),
  );
};
