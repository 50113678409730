import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocaleWrapper extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  override toString(): string {
    return this.service.currentLang;
  }
}
