// Service mostly based on this implementation: https://stackoverflow.com/a/76075958

import { Injectable } from '@angular/core';
import { filter, fromEvent, merge, Observable, of, repeat, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private readonly timeoutDelay = 1000 * 60 * 30; // 30 minutes
  readonly onInactive$: Observable<void>;

  constructor() {
    const events: string[] = ['keypress', 'click', 'wheel', 'mousemove', 'touchstart'];
    const eventObservables: Observable<Event>[] = events.map((event) => fromEvent(document, event));

    this.onInactive$ = merge(...eventObservables).pipe(
      timeout<Event, Observable<undefined>>({ each: this.timeoutDelay, with: () => of(undefined) }),
      filter((event: Event | undefined): event is undefined => event === undefined),
      repeat(),
    );
  }
}
