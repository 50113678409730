<div class="py-4 py-md-5 bg-background-privacy-statement">
  <div class="container-xl container-hide-padding-mobile">
    <div class="image-container rounded">
      <img title="Magnit NL" src="https://brainnetsacdn.blob.core.windows.net/content/logo-magnit_300x108.png" />
    </div>
    <div class="privacy-statement rounded">
      <div class="mt-4">
        <h1>{{ 'privacy-statement.header' | translate }}</h1>
      </div>
      <div class="mt-4 mb-4">
        {{ 'privacy-statement.instruction' | translate }}
      </div>
      <div class="scroll-outer-container rounded">
        <ng-scrollbar class="scroll-container" thumbClass="scrollbar-thumb" pointerEventsMethod="scrollbar">
          @if (displayStatement) {
            <div>
              <div [innerHTML]="displayStatement"></div>
            </div>
          }
        </ng-scrollbar>
      </div>
      @if (displayStatement) {
        <div class="buttons mt-4">
          <button type="button" class="btn btn-primary min-w-100" (click)="accept()">
            {{ 'privacy-statement.accept' | translate }}
          </button>
          <button type="button" class="btn btn-secondary min-w-100 ml-3" (click)="reject()">
            {{ 'privacy-statement.reject' | translate }}
          </button>
        </div>
      }
    </div>
  </div>
</div>
