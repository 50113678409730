import { Injectable } from '@angular/core';

import { EventService } from './event.service';
import { CacheService } from './cache.service';
import { SignalRService } from './signalr.service';
import { environment } from '@env';
import { AuthenticationService } from '~/app/auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ScreeningSignalRService extends SignalRService {
  constructor(
    authService: AuthenticationService,
    private eventService: EventService,
    cacheService: CacheService,
  ) {
    super(authService, cacheService);
  }

  override init(): void {
    super.init(environment.screeningSignalRUrl);
    this.registerEventListeners();
  }

  private registerEventListeners(): void {
    this.hubConnection!.on('OnScreeningDelegated', (data: any) => {
      this.eventService.screeningDelegatedEvent.emit(data.screeningId);
    });

    this.hubConnection!.on('OnBigNumberRegistered', (data: any) => {
      this.eventService.bigNumberRegisteredEvent.emit(data.screeningId);
    });

    this.hubConnection!.on('OnDelegatedIntegrityCheckStarted', (data: any) => {
      this.eventService.delegatedIntegrityCheckStartedEvent.emit(data.screeningId);
    });

    this.hubConnection!.on('OnDocumentLinkedToScreeningItem', (data: any) => {
      this.eventService.documentLinkedToScreeningItemEvent.emit(data.screeningId);
    });

    this.hubConnection!.on('OnFormerEmployersRegistered', (data: any) => {
      this.eventService.formerEmployersRegisteredEvent.emit(data.screeningId);
    });

    this.hubConnection!.on('OnIdentityVerificationStarted', (data: any) => {
      this.eventService.identityVerificationStartedEvent.emit(data.screeningId);
    });

    this.hubConnection!.on('OnIntegrityCheckStarted', (data: any) => {
      this.eventService.integrityCheckStartedEvent.emit(data.screeningId);
    });

    this.hubConnection!.on('OnProfessionalMutationSaved', (data: any) => {
      this.eventService.professionalMutationSavedEvent.emit(data.screeningId);
    });
  }
}
