import { Inject, inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { filter, of, switchMap, take } from 'rxjs';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';

import { isValidAccount } from './authentication.service';
import { msalConfig } from './auth-config';

@Injectable({
  providedIn: 'root',
})
class BlaService {
  constructor(@Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration) {}
}

export const ActiveAccountGuard: CanActivateFn = (route, state) => {
  const msalService = inject(MsalService);
  const msalBroadcastService: MsalBroadcastService = inject(MsalBroadcastService);

  const msalGuardConfig = inject(BlaService).msalGuardConfig;

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    switchMap(() => {
      const activeAccount = checkAndSetActiveAccount();

      if (!activeAccount) {
        loginRedirect();
        return of(false);
      }
      return of(true);
    }),
    take(1),
  );
  function loginRedirect() {
    if (msalGuardConfig.authRequest) {
      msalService.loginRedirect({
        ...msalGuardConfig.authRequest,
        redirectStartPage: state.url,
      } as RedirectRequest);
    } else {
      msalService.loginRedirect();
    }
  }

  function checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    const activeAccount = msalService.instance.getActiveAccount();
    if (isValidAccount(activeAccount)) {
      return activeAccount;
    }

    const accounts = msalService.instance.getAllAccounts();

    for (const acc of accounts) {
      if (isValidAccount(acc)) {
        msalService.instance.setActiveAccount(acc);
        return acc;
      }
    }

    return null;
  }
};
