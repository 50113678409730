import { inject, Injector, NgModule, runInInjectionContext } from '@angular/core';
import { CanActivateFn, RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { from, concatMap, Observable, of, takeWhile, last } from 'rxjs';

import { LandingComponent } from './landing/landing.component';
import { NotFoundComponent as NotFoundComponent } from './notfound/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { EndUserLicenseAgreementComponent } from './end-user-license-agreement/end-user-license-agreement.component';
import { endUserLicenseAgreementResolver } from './end-user-license-agreement/end-user-license-agreement.resolver';
import { ActiveAccountGuard as activeAccountGuard } from './auth/active-account.guard';
import { avgGuard } from './privacy-statement/avg.guard';
import { eulaGuard } from './end-user-license-agreement/eula.guard';

export function sequentialGuards(guards: CanActivateFn[]): CanActivateFn {
  return (route, state) => {
    const injectionContext = inject(Injector);
    // Convert an array into an observable.
    return from(guards).pipe(
      // For each guard, fire canActivate and wait for it to complete.
      concatMap((guard) => {
        return runInInjectionContext(injectionContext, () => {
          const guardResult = guard(route, state);
          if (guardResult instanceof Observable) {
            return guardResult;
          } else if (guardResult instanceof Promise) {
            return from(guardResult);
          } else {
            return of(guardResult);
          }
        });
      }),
      // Don't execute the next guard if the current guard's result is not true.
      takeWhile((value) => value === true, true),
      // Return the last guard's result.
      last(),
    );
  };
}

export const msalGuardFn: CanActivateFn = (route, state) => {
  const msalGuard = inject(MsalGuard);
  return msalGuard.canActivate(route, state);
};

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'privacy-statement',
    component: PrivacyStatementComponent,
    canActivate: [sequentialGuards([msalGuardFn, activeAccountGuard])],
  },
  {
    path: 'end-user-license-agreement',
    component: EndUserLicenseAgreementComponent,
    resolve: { endUserLicenseAgreement: endUserLicenseAgreementResolver },
    canActivate: [sequentialGuards([msalGuardFn, activeAccountGuard])],
  },
  {
    path: 'supplier',
    loadChildren: () => import('./supplier/supplier.module').then((e) => e.SupplierModule),
    canActivate: [sequentialGuards([msalGuardFn, activeAccountGuard, avgGuard, eulaGuard])],
  },
  {
    /**  redirect invalid contract-signing urls */
    path: 'overviews/contracts/contract-signing',
    redirectTo: 'supplier/overviews/contracts/contract-signing',
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((e) => e.AdminModule),
    canActivate: [sequentialGuards([msalGuardFn, activeAccountGuard])],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
