import { Component } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MaintenancePageComponent } from '../layout/maintenance-page/maintenance-page.component';
import { environment } from '@env';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: true,
  imports: [FeatherModule, NgClass, RouterLink, MaintenancePageComponent, TranslateModule],
})
export class LandingComponent {
  readonly telephoneNumber: string = '+31 (0) 30 602 16 17';
  readonly emailAddress: string = 'infoNL@magnitglobal.com';
  readonly netiveOrganisations: string[] = [
    'Gemeente Beverwijk',
    'Movares',
    'Royal HaskoningDHV',
    'Sweco',
    'Waterschap Limburg',
    'Waterschap Scheldestromen',
  ];
  readonly netiveLink: string = 'https://magnit.my.site.com/vms/s/login/?language=nl_NL&bid=magnit';
  readonly legacyCustomerPortalLink = environment.externalLinks.legacyCustomerPortalLink;
  // private cookieSelectedApp = 'selected_app';
  // private cookieExpirationDays = 365;
  readonly customerPortalIsLive = false; // environment.customerPortalIsLive;
  readonly registerLink = getRegisterUrl();
}

function getRegisterUrl(): string {
  const dutchLocales: string[] = ['nl', 'nl-NL'];
  const locale = navigator.language;
  const url = dutchLocales.includes(locale)
    ? environment.externalLinks.dutchRegisterLink
    : environment.externalLinks.englishRegisterLink;
  return url;
}
