import { Injectable } from '@angular/core';

import { EventService } from './event.service';
import { CacheService } from './cache.service';
import { SignalRService } from './signalr.service';
import { JobRequestTaggedEvent } from '~/v2/data/schema/JobRequestTaggedEvent';
import { Tag } from '~/v2/data/schema/generated/Tag';
import { environment } from '@env';
import { AuthenticationService } from '~/app/auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class RecruitmentSignalRService extends SignalRService {
  constructor(
    authService: AuthenticationService,
    private eventService: EventService,
    cacheService: CacheService,
  ) {
    super(authService, cacheService);
  }

  override init(): void {
    super.init(environment.recruitmentSignalRUrl);
    this.registerEventListeners();
  }

  private registerEventListeners(): void {
    const { hubConnection } = this;
    if (!hubConnection) {
      return;
    }

    hubConnection.on('OnJobRequestTagRemoved', (data: any) => {
      if (this.isOwnEvent(data.operationId)) {
        return;
      }

      const tag = Tag.Unspecified;

      this.emitJobRequestTaggedEvent(data.jobRequestId, data.previousTag, tag, null);
    });

    hubConnection.on('OnJobRequestTagged', (data: any) => {
      if (this.isOwnEvent(data.operationId)) {
        return;
      }

      this.emitJobRequestTaggedEvent(data.jobRequestId, data.previousTag, data.tag, data.tagId);
    });

    hubConnection.on('OnSecondeeProposed', (data: any) => {
      this.eventService.secondeeProposedEvent.emit(data);
      if (this.isOwnEvent(data.operationId)) {
        return;
      }

      this.eventService.proposalCreatedEvent.emit();
    });

    hubConnection.on('OnDraftSecondeeProposed', (data: any) => {
      this.eventService.secondeeProposedEvent.emit(data);
      if (this.isOwnEvent(data.operationId)) {
        return;
      }

      this.eventService.proposalCreatedEvent.emit();
      this.eventService.draftDeletedEvent.emit();
      this.eventService.rowDeletedEvent.emit({
        id: data.draftId,
        field: 'draftId',
      });
    });

    hubConnection.on('OnProposalWithdrawn', (data: any) => {
      this.eventService.proposalWithdrawnEvent.emit(data.secondeeId);
      this.eventService.rowDeletedEvent.emit({
        id: data.secondeeId,
        field: 'secondeeId',
      });

      if (this.isOwnEvent(data.operationId)) {
        this.eventService.draftCreatedEvent.emit();
      }
    });

    hubConnection.on('OnDraftCreated', (data: any) => {
      if (this.isOwnEvent(data.operationId)) {
        return;
      }

      this.eventService.draftCreatedEvent.emit();
    });

    hubConnection.on('OnDraftDiscarded', (data: any) => {
      if (this.isOwnEvent(data.operationId)) {
        return;
      }

      this.eventService.draftDeletedEvent.emit();
      this.eventService.rowDeletedEvent.emit({
        id: data.draftId,
        field: 'draftId',
      });
    });

    hubConnection.on('OnProposalRegistrationFailed', (data: any) => {
      this.eventService.proposalRegistrationFailedEvent.emit(data);
      this.eventService.draftCreatedEvent.emit();
    });

    hubConnection.on('OnProposalRegistered', (data: any) => {});
  }

  private emitJobRequestTaggedEvent(jobRequestId: string, previousTag: Tag, tag: Tag, newTagId: string | null): void {
    const oldTag: any = Tag[previousTag];
    const newTag: any = Tag[tag];
    const taggedEvent: JobRequestTaggedEvent = {
      jobRequestId: jobRequestId,
      oldTag: oldTag,
      newTag: newTag,
      newTagId: newTagId,
    };

    this.eventService.jobRequestTaggedEvent.emit(taggedEvent);
  }
}
