import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { BoabaasUser } from '../auth/authentication.service';
import {
  EndUserLicenseAgreement,
  EndUserLicenseAgreementService,
} from '../end-user-license-agreement/end-user-license-agreement.service';

export const eulaGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router = inject(Router);
  const msalService = inject(MsalService);
  const endUserLicenseAgreementService = inject(EndUserLicenseAgreementService);

  const activeAccount = msalService.instance.getActiveAccount();
  if (!activeAccount) {
    return false;
  }

  const boabaasUser = new BoabaasUser(activeAccount);
  if (boabaasUser.isAdmin) {
    return true;
  }

  return endUserLicenseAgreementService.hasAgreed().pipe(
    switchMap((hasAgreed: boolean) => {
      if (!hasAgreed) {
        return endUserLicenseAgreementService.retrieve().pipe(
          map((result: EndUserLicenseAgreement) => {
            if (result) {
              return router.createUrlTree(['/end-user-license-agreement']);
            }
            return true;
          }),
        );
      } else {
        return of(true);
      }
    }),
  );
};
